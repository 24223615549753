/**
 * @fileoverview gRPC-Web generated client stub for matchmaking
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.0
// source: matchmaking.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.matchmaking = require('./matchmaking_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.matchmaking.MatchmakingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.matchmaking.MatchmakingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.matchmaking.EnterQueueRequest,
 *   !proto.matchmaking.GameFoundNotification>}
 */
const methodDescriptor_MatchmakingService_EnterQueue = new grpc.web.MethodDescriptor(
  '/matchmaking.MatchmakingService/EnterQueue',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.matchmaking.EnterQueueRequest,
  proto.matchmaking.GameFoundNotification,
  /**
   * @param {!proto.matchmaking.EnterQueueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.matchmaking.GameFoundNotification.deserializeBinary
);


/**
 * @param {!proto.matchmaking.EnterQueueRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.matchmaking.GameFoundNotification>}
 *     The XHR Node Readable Stream
 */
proto.matchmaking.MatchmakingServiceClient.prototype.enterQueue =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/matchmaking.MatchmakingService/EnterQueue',
      request,
      metadata || {},
      methodDescriptor_MatchmakingService_EnterQueue);
};


/**
 * @param {!proto.matchmaking.EnterQueueRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.matchmaking.GameFoundNotification>}
 *     The XHR Node Readable Stream
 */
proto.matchmaking.MatchmakingServicePromiseClient.prototype.enterQueue =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/matchmaking.MatchmakingService/EnterQueue',
      request,
      metadata || {},
      methodDescriptor_MatchmakingService_EnterQueue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.matchmaking.LiveCheckMessage,
 *   !proto.matchmaking.LiveCheckMessage>}
 */
const methodDescriptor_MatchmakingService_LiveCheck = new grpc.web.MethodDescriptor(
  '/matchmaking.MatchmakingService/LiveCheck',
  grpc.web.MethodType.UNARY,
  proto.matchmaking.LiveCheckMessage,
  proto.matchmaking.LiveCheckMessage,
  /**
   * @param {!proto.matchmaking.LiveCheckMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.matchmaking.LiveCheckMessage.deserializeBinary
);


/**
 * @param {!proto.matchmaking.LiveCheckMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.matchmaking.LiveCheckMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.matchmaking.LiveCheckMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.matchmaking.MatchmakingServiceClient.prototype.liveCheck =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/matchmaking.MatchmakingService/LiveCheck',
      request,
      metadata || {},
      methodDescriptor_MatchmakingService_LiveCheck,
      callback);
};


/**
 * @param {!proto.matchmaking.LiveCheckMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.matchmaking.LiveCheckMessage>}
 *     Promise that resolves to the response
 */
proto.matchmaking.MatchmakingServicePromiseClient.prototype.liveCheck =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/matchmaking.MatchmakingService/LiveCheck',
      request,
      metadata || {},
      methodDescriptor_MatchmakingService_LiveCheck);
};


module.exports = proto.matchmaking;


// src/phaserGame.js
import Phaser from 'phaser';
import { GameScene } from './scenes/GameScene'; // Adjust the path as needed

const config = {
  type: Phaser.AUTO,
  width: window.innerWidth, // Set to the full width of the browser window
  height: window.innerHeight, // Set to the full height of the browser window
  parent: 'phaser-container', // The ID of the div where Phaser will render
  scene: [GameScene],
  physics: {
    default: 'arcade',
    arcade: {
      debug: false,
    },
  },
  scale: {
    mode: Phaser.Scale.RESIZE, // Makes the game resize dynamically
    autoCenter: Phaser.Scale.CENTER_BOTH, // Centers the game canvas both horizontally and vertically
  },
};

export const initializePhaser = () => {
  const game = new Phaser.Game(config);

  // Update game size on window resize
  window.addEventListener('resize', () => {
    game.scale.resize(window.innerWidth, window.innerHeight);
  });

  return game;
};
import { MatchmakingServiceClient } from '../generated/matchmaking_grpc_web_pb';
import { EnterQueueRequest } from '../generated/matchmaking_pb';

class MatchmakingService {
  /**
   * @param {string} host The endpoint of your gRPC server. 
   *                      For example: "http://localhost:8080"
   */
  constructor(host) {
    // Create the gRPC-web client instance
    this.client = new MatchmakingServiceClient(host, null, null);
  }
  
  /**
   * Subscribe to game found notifications for a given playerId.
   * Returns a stream. Callers can cancel if needed.
   *
   * The callback `onData` will receive { gameId: string, opponentId: string } as objects.
   *
   * @param {string} playerId
   * @param {function} onData - Called with each GameFoundNotification object
   * @param {function} onEnd - Called when the stream ends
   * @param {function} onError - Called on error
   * @returns {ClientReadableStream<GameFoundNotification>}
   */
  enterQueue(playerId, onData, onEnd, onError) {
    const request = new EnterQueueRequest();
    request.setPlayerId(playerId);

    // According to the proto, the method name is NotifyGameFound
    const stream = this.client.enterQueue(request, {});

    stream.on('data', (response) => {
      onData(response.toObject()); // { gameId: string, opponentId: string }
    });

    stream.on('end', () => {
      if (onEnd) onEnd();
    });

    stream.on('error', (err) => {
      if (onError) onError(err);
    });

    return stream;
  }
}

export default MatchmakingService;
import React from 'react';

function GameOverScreen({ winnerId, onPlayAgain }) {
  return (
    <div>
      <h2>Game Over</h2>
      <p>Winner: {winnerId}</p>
      <button onClick={onPlayAgain}>Play Again</button>
    </div>
  );
}

export default GameOverScreen;
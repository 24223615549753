/**
 * @fileoverview gRPC-Web generated client stub for game
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.0
// source: game.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.game = require('./game_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.game.GameServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.game.GameServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.game.PlayerAction,
 *   !proto.game.Empty>}
 */
const methodDescriptor_GameService_SendPlayerAction = new grpc.web.MethodDescriptor(
  '/game.GameService/SendPlayerAction',
  grpc.web.MethodType.UNARY,
  proto.game.PlayerAction,
  proto.game.Empty,
  /**
   * @param {!proto.game.PlayerAction} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.game.Empty.deserializeBinary
);


/**
 * @param {!proto.game.PlayerAction} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.game.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.game.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.game.GameServiceClient.prototype.sendPlayerAction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/game.GameService/SendPlayerAction',
      request,
      metadata || {},
      methodDescriptor_GameService_SendPlayerAction,
      callback);
};


/**
 * @param {!proto.game.PlayerAction} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.game.Empty>}
 *     Promise that resolves to the response
 */
proto.game.GameServicePromiseClient.prototype.sendPlayerAction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/game.GameService/SendPlayerAction',
      request,
      metadata || {},
      methodDescriptor_GameService_SendPlayerAction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.game.PlayerId,
 *   !proto.game.GameState>}
 */
const methodDescriptor_GameService_ReportGameState = new grpc.web.MethodDescriptor(
  '/game.GameService/ReportGameState',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.game.PlayerId,
  proto.game.GameState,
  /**
   * @param {!proto.game.PlayerId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.game.GameState.deserializeBinary
);


/**
 * @param {!proto.game.PlayerId} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.game.GameState>}
 *     The XHR Node Readable Stream
 */
proto.game.GameServiceClient.prototype.reportGameState =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/game.GameService/ReportGameState',
      request,
      metadata || {},
      methodDescriptor_GameService_ReportGameState);
};


/**
 * @param {!proto.game.PlayerId} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.game.GameState>}
 *     The XHR Node Readable Stream
 */
proto.game.GameServicePromiseClient.prototype.reportGameState =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/game.GameService/ReportGameState',
      request,
      metadata || {},
      methodDescriptor_GameService_ReportGameState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.game.PlayerId,
 *   !proto.game.GameError>}
 */
const methodDescriptor_GameService_ReportErrors = new grpc.web.MethodDescriptor(
  '/game.GameService/ReportErrors',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.game.PlayerId,
  proto.game.GameError,
  /**
   * @param {!proto.game.PlayerId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.game.GameError.deserializeBinary
);


/**
 * @param {!proto.game.PlayerId} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.game.GameError>}
 *     The XHR Node Readable Stream
 */
proto.game.GameServiceClient.prototype.reportErrors =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/game.GameService/ReportErrors',
      request,
      metadata || {},
      methodDescriptor_GameService_ReportErrors);
};


/**
 * @param {!proto.game.PlayerId} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.game.GameError>}
 *     The XHR Node Readable Stream
 */
proto.game.GameServicePromiseClient.prototype.reportErrors =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/game.GameService/ReportErrors',
      request,
      metadata || {},
      methodDescriptor_GameService_ReportErrors);
};


module.exports = proto.game;


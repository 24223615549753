import React, { useState, useEffect, useCallback } from 'react';
import MainMenu from './components/overlays/MainMenu';
import GameScreen from './components/overlays/GameScreen';
import GameOverScreen from './components/overlays/GameOverScreen';

function App() {
  const [stage, setStage] = useState('menu'); // 'menu', 'game', 'gameover'
  const [playerId, setPlayerId] = useState(null);
  const [gameId, setGameId] = useState(null);
  const [opponentId, setOpponentId] = useState(null);
  const [winnerId, setWinnerId] = useState(null);

  useEffect(() => {
    // Generate playerId once on mount (or fetch from database in the future)
    const randomNumber = Math.floor(Math.random() * 100) + 1;
    const generatedPlayerId = `player_${randomNumber}`;
    setPlayerId(generatedPlayerId);
  }, []);

  const handleMatchFound = useCallback(({ gameId: foundGameId, opponentId: foundOpponentId }) => {
    setGameId(foundGameId);
    setOpponentId(foundOpponentId);
    setStage('game');
  }, []);
  
  const handleGameOver = useCallback((winner) => {
    setWinnerId(winner);
    setStage('gameover');
  }, []);
  
  const handlePlayAgain = useCallback(() => {
    setGameId(null);
    setOpponentId(null);
    setWinnerId(null);
    setStage('menu');
  }, []);

  // If playerId is not yet generated, show a loading state
  if (!playerId) {
    return <div>Loading player identity...</div>;
  }

  return (
    <div style={{ textAlign: 'center', height: '100vh', margin: 0, padding: 0 }}>
      {stage === 'menu' && (
        <MainMenu
          playerId={playerId}
          onMatchFound={handleMatchFound}
        />
      )}
      {stage === 'game' && (
        <GameScreen
          playerId={playerId}
          gameId={gameId}
          opponentId={opponentId}
          onGameOver={handleGameOver}
        />
      )}
      {stage === 'gameover' && (
        <GameOverScreen
          winnerId={winnerId}
          onPlayAgain={handlePlayAgain}
        />
      )}
    </div>
  );
}

export default App;
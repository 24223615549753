// source: game.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.game.ActionType', null, global);
goog.exportSymbol('proto.game.Empty', null, global);
goog.exportSymbol('proto.game.GameError', null, global);
goog.exportSymbol('proto.game.GameInfo', null, global);
goog.exportSymbol('proto.game.GameLifeStatus', null, global);
goog.exportSymbol('proto.game.GameState', null, global);
goog.exportSymbol('proto.game.Item', null, global);
goog.exportSymbol('proto.game.ItemActionDetails', null, global);
goog.exportSymbol('proto.game.PlayerAction', null, global);
goog.exportSymbol('proto.game.PlayerId', null, global);
goog.exportSymbol('proto.game.PlayerItem', null, global);
goog.exportSymbol('proto.game.PlayerState', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.PlayerAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.PlayerAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.PlayerAction.displayName = 'proto.game.PlayerAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.PlayerItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.PlayerItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.PlayerItem.displayName = 'proto.game.PlayerItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.PlayerState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.game.PlayerState.repeatedFields_, null);
};
goog.inherits(proto.game.PlayerState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.PlayerState.displayName = 'proto.game.PlayerState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.GameInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.GameInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.GameInfo.displayName = 'proto.game.GameInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.ItemActionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.ItemActionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.ItemActionDetails.displayName = 'proto.game.ItemActionDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.GameState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.GameState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.GameState.displayName = 'proto.game.GameState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.GameError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.GameError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.GameError.displayName = 'proto.game.GameError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.PlayerId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.PlayerId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.PlayerId.displayName = 'proto.game.PlayerId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.Empty.displayName = 'proto.game.Empty';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.PlayerAction.prototype.toObject = function(opt_includeInstance) {
  return proto.game.PlayerAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.PlayerAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.PlayerAction.toObject = function(includeInstance, msg) {
  var f, obj = {
playerid: jspb.Message.getFieldWithDefault(msg, 1, ""),
action: jspb.Message.getFieldWithDefault(msg, 2, 0),
itemindex: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.PlayerAction}
 */
proto.game.PlayerAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.PlayerAction;
  return proto.game.PlayerAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.PlayerAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.PlayerAction}
 */
proto.game.PlayerAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerid(value);
      break;
    case 2:
      var value = /** @type {!proto.game.ActionType} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemindex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.PlayerAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.PlayerAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.PlayerAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.PlayerAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getItemindex();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string playerId = 1;
 * @return {string}
 */
proto.game.PlayerAction.prototype.getPlayerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.PlayerAction} returns this
 */
proto.game.PlayerAction.prototype.setPlayerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActionType Action = 2;
 * @return {!proto.game.ActionType}
 */
proto.game.PlayerAction.prototype.getAction = function() {
  return /** @type {!proto.game.ActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.game.ActionType} value
 * @return {!proto.game.PlayerAction} returns this
 */
proto.game.PlayerAction.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 ItemIndex = 3;
 * @return {number}
 */
proto.game.PlayerAction.prototype.getItemindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.PlayerAction} returns this
 */
proto.game.PlayerAction.prototype.setItemindex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.PlayerItem.prototype.toObject = function(opt_includeInstance) {
  return proto.game.PlayerItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.PlayerItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.PlayerItem.toObject = function(includeInstance, msg) {
  var f, obj = {
index: jspb.Message.getFieldWithDefault(msg, 1, 0),
item: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.PlayerItem}
 */
proto.game.PlayerItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.PlayerItem;
  return proto.game.PlayerItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.PlayerItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.PlayerItem}
 */
proto.game.PlayerItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {!proto.game.Item} */ (reader.readEnum());
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.PlayerItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.PlayerItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.PlayerItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.PlayerItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItem();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.game.PlayerItem.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.PlayerItem} returns this
 */
proto.game.PlayerItem.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Item item = 2;
 * @return {!proto.game.Item}
 */
proto.game.PlayerItem.prototype.getItem = function() {
  return /** @type {!proto.game.Item} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.game.Item} value
 * @return {!proto.game.PlayerItem} returns this
 */
proto.game.PlayerItem.prototype.setItem = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.game.PlayerState.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.PlayerState.prototype.toObject = function(opt_includeInstance) {
  return proto.game.PlayerState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.PlayerState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.PlayerState.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
health: jspb.Message.getFieldWithDefault(msg, 2, 0),
itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.game.PlayerItem.toObject, includeInstance),
cuffed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.PlayerState}
 */
proto.game.PlayerState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.PlayerState;
  return proto.game.PlayerState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.PlayerState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.PlayerState}
 */
proto.game.PlayerState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHealth(value);
      break;
    case 3:
      var value = new proto.game.PlayerItem;
      reader.readMessage(value,proto.game.PlayerItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCuffed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.PlayerState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.PlayerState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.PlayerState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.PlayerState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHealth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.game.PlayerItem.serializeBinaryToWriter
    );
  }
  f = message.getCuffed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.game.PlayerState.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.PlayerState} returns this
 */
proto.game.PlayerState.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 health = 2;
 * @return {number}
 */
proto.game.PlayerState.prototype.getHealth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.PlayerState} returns this
 */
proto.game.PlayerState.prototype.setHealth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated PlayerItem items = 3;
 * @return {!Array<!proto.game.PlayerItem>}
 */
proto.game.PlayerState.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.game.PlayerItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.game.PlayerItem, 3));
};


/**
 * @param {!Array<!proto.game.PlayerItem>} value
 * @return {!proto.game.PlayerState} returns this
*/
proto.game.PlayerState.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.game.PlayerItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.game.PlayerItem}
 */
proto.game.PlayerState.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.game.PlayerItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.game.PlayerState} returns this
 */
proto.game.PlayerState.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool cuffed = 4;
 * @return {boolean}
 */
proto.game.PlayerState.prototype.getCuffed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.game.PlayerState} returns this
 */
proto.game.PlayerState.prototype.setCuffed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.GameInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.game.GameInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.GameInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.GameInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
reloadperformed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
gamestatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
winnerid: jspb.Message.getFieldWithDefault(msg, 3, ""),
lastshotlive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
totalbullets: jspb.Message.getFieldWithDefault(msg, 5, 0),
livebullets: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.GameInfo}
 */
proto.game.GameInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.GameInfo;
  return proto.game.GameInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.GameInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.GameInfo}
 */
proto.game.GameInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReloadperformed(value);
      break;
    case 2:
      var value = /** @type {!proto.game.GameLifeStatus} */ (reader.readEnum());
      msg.setGamestatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWinnerid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLastshotlive(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalbullets(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLivebullets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.GameInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.GameInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.GameInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.GameInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReloadperformed();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getGamestatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getWinnerid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastshotlive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTotalbullets();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLivebullets();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional bool reloadPerformed = 1;
 * @return {boolean}
 */
proto.game.GameInfo.prototype.getReloadperformed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.game.GameInfo} returns this
 */
proto.game.GameInfo.prototype.setReloadperformed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional GameLifeStatus gameStatus = 2;
 * @return {!proto.game.GameLifeStatus}
 */
proto.game.GameInfo.prototype.getGamestatus = function() {
  return /** @type {!proto.game.GameLifeStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.game.GameLifeStatus} value
 * @return {!proto.game.GameInfo} returns this
 */
proto.game.GameInfo.prototype.setGamestatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string winnerId = 3;
 * @return {string}
 */
proto.game.GameInfo.prototype.getWinnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.GameInfo} returns this
 */
proto.game.GameInfo.prototype.setWinnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool lastShotLive = 4;
 * @return {boolean}
 */
proto.game.GameInfo.prototype.getLastshotlive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.game.GameInfo} returns this
 */
proto.game.GameInfo.prototype.setLastshotlive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 totalBullets = 5;
 * @return {number}
 */
proto.game.GameInfo.prototype.getTotalbullets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.GameInfo} returns this
 */
proto.game.GameInfo.prototype.setTotalbullets = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 liveBullets = 6;
 * @return {number}
 */
proto.game.GameInfo.prototype.getLivebullets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.GameInfo} returns this
 */
proto.game.GameInfo.prototype.setLivebullets = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.ItemActionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.game.ItemActionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.ItemActionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ItemActionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
useditem: jspb.Message.getFieldWithDefault(msg, 1, 0),
itemuser: jspb.Message.getFieldWithDefault(msg, 2, ""),
nextshotdoubledamage: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
lookedbulletwaslive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.ItemActionDetails}
 */
proto.game.ItemActionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.ItemActionDetails;
  return proto.game.ItemActionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.ItemActionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.ItemActionDetails}
 */
proto.game.ItemActionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.game.Item} */ (reader.readEnum());
      msg.setUseditem(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemuser(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNextshotdoubledamage(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLookedbulletwaslive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.ItemActionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.ItemActionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.ItemActionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ItemActionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUseditem();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getItemuser();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNextshotdoubledamage();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLookedbulletwaslive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional Item usedItem = 1;
 * @return {!proto.game.Item}
 */
proto.game.ItemActionDetails.prototype.getUseditem = function() {
  return /** @type {!proto.game.Item} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.game.Item} value
 * @return {!proto.game.ItemActionDetails} returns this
 */
proto.game.ItemActionDetails.prototype.setUseditem = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string itemUser = 2;
 * @return {string}
 */
proto.game.ItemActionDetails.prototype.getItemuser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.ItemActionDetails} returns this
 */
proto.game.ItemActionDetails.prototype.setItemuser = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool nextShotDoubleDamage = 3;
 * @return {boolean}
 */
proto.game.ItemActionDetails.prototype.getNextshotdoubledamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.game.ItemActionDetails} returns this
 */
proto.game.ItemActionDetails.prototype.setNextshotdoubledamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool lookedBulletWasLive = 4;
 * @return {boolean}
 */
proto.game.ItemActionDetails.prototype.getLookedbulletwaslive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.game.ItemActionDetails} returns this
 */
proto.game.ItemActionDetails.prototype.setLookedbulletwaslive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.GameState.prototype.toObject = function(opt_includeInstance) {
  return proto.game.GameState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.GameState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.GameState.toObject = function(includeInstance, msg) {
  var f, obj = {
player1: (f = msg.getPlayer1()) && proto.game.PlayerState.toObject(includeInstance, f),
player2: (f = msg.getPlayer2()) && proto.game.PlayerState.toObject(includeInstance, f),
playeridturn: jspb.Message.getFieldWithDefault(msg, 3, ""),
initialmessage: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
gameinfo: (f = msg.getGameinfo()) && proto.game.GameInfo.toObject(includeInstance, f),
playeridlastshooter: jspb.Message.getFieldWithDefault(msg, 6, ""),
playeridlasttarget: jspb.Message.getFieldWithDefault(msg, 7, ""),
itemaction: (f = msg.getItemaction()) && proto.game.ItemActionDetails.toObject(includeInstance, f),
timestamp: jspb.Message.getFieldWithDefault(msg, 9, 0),
version: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.GameState}
 */
proto.game.GameState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.GameState;
  return proto.game.GameState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.GameState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.GameState}
 */
proto.game.GameState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.game.PlayerState;
      reader.readMessage(value,proto.game.PlayerState.deserializeBinaryFromReader);
      msg.setPlayer1(value);
      break;
    case 2:
      var value = new proto.game.PlayerState;
      reader.readMessage(value,proto.game.PlayerState.deserializeBinaryFromReader);
      msg.setPlayer2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayeridturn(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInitialmessage(value);
      break;
    case 5:
      var value = new proto.game.GameInfo;
      reader.readMessage(value,proto.game.GameInfo.deserializeBinaryFromReader);
      msg.setGameinfo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayeridlastshooter(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayeridlasttarget(value);
      break;
    case 8:
      var value = new proto.game.ItemActionDetails;
      reader.readMessage(value,proto.game.ItemActionDetails.deserializeBinaryFromReader);
      msg.setItemaction(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.GameState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.GameState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.GameState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.GameState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayer1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.game.PlayerState.serializeBinaryToWriter
    );
  }
  f = message.getPlayer2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.game.PlayerState.serializeBinaryToWriter
    );
  }
  f = message.getPlayeridturn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInitialmessage();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getGameinfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.game.GameInfo.serializeBinaryToWriter
    );
  }
  f = message.getPlayeridlastshooter();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlayeridlasttarget();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getItemaction();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.game.ItemActionDetails.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional PlayerState player1 = 1;
 * @return {?proto.game.PlayerState}
 */
proto.game.GameState.prototype.getPlayer1 = function() {
  return /** @type{?proto.game.PlayerState} */ (
    jspb.Message.getWrapperField(this, proto.game.PlayerState, 1));
};


/**
 * @param {?proto.game.PlayerState|undefined} value
 * @return {!proto.game.GameState} returns this
*/
proto.game.GameState.prototype.setPlayer1 = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.GameState} returns this
 */
proto.game.GameState.prototype.clearPlayer1 = function() {
  return this.setPlayer1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.GameState.prototype.hasPlayer1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PlayerState player2 = 2;
 * @return {?proto.game.PlayerState}
 */
proto.game.GameState.prototype.getPlayer2 = function() {
  return /** @type{?proto.game.PlayerState} */ (
    jspb.Message.getWrapperField(this, proto.game.PlayerState, 2));
};


/**
 * @param {?proto.game.PlayerState|undefined} value
 * @return {!proto.game.GameState} returns this
*/
proto.game.GameState.prototype.setPlayer2 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.GameState} returns this
 */
proto.game.GameState.prototype.clearPlayer2 = function() {
  return this.setPlayer2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.GameState.prototype.hasPlayer2 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string playerIdTurn = 3;
 * @return {string}
 */
proto.game.GameState.prototype.getPlayeridturn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.GameState} returns this
 */
proto.game.GameState.prototype.setPlayeridturn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool initialMessage = 4;
 * @return {boolean}
 */
proto.game.GameState.prototype.getInitialmessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.game.GameState} returns this
 */
proto.game.GameState.prototype.setInitialmessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional GameInfo gameInfo = 5;
 * @return {?proto.game.GameInfo}
 */
proto.game.GameState.prototype.getGameinfo = function() {
  return /** @type{?proto.game.GameInfo} */ (
    jspb.Message.getWrapperField(this, proto.game.GameInfo, 5));
};


/**
 * @param {?proto.game.GameInfo|undefined} value
 * @return {!proto.game.GameState} returns this
*/
proto.game.GameState.prototype.setGameinfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.GameState} returns this
 */
proto.game.GameState.prototype.clearGameinfo = function() {
  return this.setGameinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.GameState.prototype.hasGameinfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string playerIdLastShooter = 6;
 * @return {string}
 */
proto.game.GameState.prototype.getPlayeridlastshooter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.GameState} returns this
 */
proto.game.GameState.prototype.setPlayeridlastshooter = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string playerIdLastTarget = 7;
 * @return {string}
 */
proto.game.GameState.prototype.getPlayeridlasttarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.GameState} returns this
 */
proto.game.GameState.prototype.setPlayeridlasttarget = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional ItemActionDetails itemAction = 8;
 * @return {?proto.game.ItemActionDetails}
 */
proto.game.GameState.prototype.getItemaction = function() {
  return /** @type{?proto.game.ItemActionDetails} */ (
    jspb.Message.getWrapperField(this, proto.game.ItemActionDetails, 8));
};


/**
 * @param {?proto.game.ItemActionDetails|undefined} value
 * @return {!proto.game.GameState} returns this
*/
proto.game.GameState.prototype.setItemaction = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.GameState} returns this
 */
proto.game.GameState.prototype.clearItemaction = function() {
  return this.setItemaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.GameState.prototype.hasItemaction = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 timestamp = 9;
 * @return {number}
 */
proto.game.GameState.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.GameState} returns this
 */
proto.game.GameState.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string version = 10;
 * @return {string}
 */
proto.game.GameState.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.GameState} returns this
 */
proto.game.GameState.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.GameError.prototype.toObject = function(opt_includeInstance) {
  return proto.game.GameError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.GameError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.GameError.toObject = function(includeInstance, msg) {
  var f, obj = {
message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.GameError}
 */
proto.game.GameError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.GameError;
  return proto.game.GameError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.GameError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.GameError}
 */
proto.game.GameError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.GameError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.GameError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.GameError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.GameError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.game.GameError.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.GameError} returns this
 */
proto.game.GameError.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.PlayerId.prototype.toObject = function(opt_includeInstance) {
  return proto.game.PlayerId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.PlayerId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.PlayerId.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.PlayerId}
 */
proto.game.PlayerId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.PlayerId;
  return proto.game.PlayerId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.PlayerId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.PlayerId}
 */
proto.game.PlayerId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.PlayerId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.PlayerId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.PlayerId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.PlayerId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.game.PlayerId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.PlayerId} returns this
 */
proto.game.PlayerId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.game.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.Empty}
 */
proto.game.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.Empty;
  return proto.game.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.Empty}
 */
proto.game.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.game.Item = {
  NONE: 0,
  HEAL: 1,
  DOUBLEDAMAGE: 2,
  BLOCKOPPONENT: 3,
  PEEKNEXT: 4,
  POPROUND: 5
};

/**
 * @enum {number}
 */
proto.game.ActionType = {
  CONNECT: 0,
  SHOOTSELF: 1,
  SHOOTOTHER: 2,
  USEITEM: 3
};

/**
 * @enum {number}
 */
proto.game.GameLifeStatus = {
  GOING: 0,
  PLAYERWON: 1,
  PLAYERDC: 2
};

goog.object.extend(exports, proto.game);

import store from '../../store';
import Phaser from 'phaser';

export class GameScene extends Phaser.Scene {
  constructor() {
    super('GameScene');
  }

  preload() {
    // Load assets
    this.load.image('explosion', 'assets/images/explosion.png'); // Add explosion sprite
  }

  create() {
    // Create the white background
    // this.add.image(0, 0, 'background').setOrigin(0).setDisplaySize(this.scale.width, this.scale.height);
    this.cameras.main.setBackgroundColor('#FFFFFF')

    // Subscribe to Redux state changes
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      this.updateGame(state.game);
    });

    // Load explosion VFX assets
    this.explosionGroup = this.add.group();
  }

  updateGame(gameState) {
    const hasLastShooter = gameState.playeridlastshooter && gameState.playeridlastshooter.trim() !== '';
    const isLastShotLive = gameState.gameinfo.lastshotlive === true;

    // Check if conditions have just become true
    if (hasLastShooter && isLastShotLive) {
      this.playExplosionVFX();
    }

    // Update other game objects based on new state
    console.log('Game state updated:', gameState);
  }

  playExplosionVFX() {
    const explosion = this.add.sprite(this.scale.width / 2, this.scale.height / 2, 'explosion');
    explosion.setScale(0.5); // Adjust size
    explosion.setAlpha(1);

    this.tweens.add({
      targets: explosion,
      alpha: 0,
      duration: 500, // Explosion fade-out time
      onComplete: () => {
        explosion.destroy();
      },
    });
  }

  destroy() {
    // Clean up subscription when the scene is destroyed
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}
import React, { useState } from 'react';
import MatchmakingService from '../../grpc/services/matchmakingService';

const GRPC_SERVICE_ADDRESS = process.env.REACT_APP_GRPC_SERVICE_ADDRESS || 'https://triumviart-games.com';

function MainMenu({ playerId, onMatchFound }) {
  const [isFindingMatch, setIsFindingMatch] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const matchmakingService = new MatchmakingService(GRPC_SERVICE_ADDRESS);

  const findMatch = async () => {
    setIsFindingMatch(true);
    setErrorMessage(null);

    try {
      const stream = matchmakingService.enterQueue(
        playerId,
        (gameFoundNotification) => {
          console.log('Received game found response:', gameFoundNotification);
          const { gameId, opponentId } = gameFoundNotification;
          onMatchFound({ gameId, opponentId });
          stream.cancel();
        },
        () => {
          console.log('Game found notification stream ended.');
          setIsFindingMatch(false);
        },
        (err) => {
          console.error('Stream error:', err);
          setErrorMessage('Error receiving game found notification.');
          setIsFindingMatch(false);
        }
      );
    } catch (err) {
      console.error('Error entering queue:', err);
      setErrorMessage('Failed to enter queue due to an error.');
      setIsFindingMatch(false);
    }
  };

  return (
    <div>
      <h1>Main Menu</h1>
      <p>Player ID: {playerId}</p>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {isFindingMatch ? (
        <p>Finding match... please wait.</p>
      ) : (
        <button onClick={findMatch}>Find Match</button>
      )}
    </div>
  );
}

export default MainMenu;